<template>
   <div id="cover"> 
    <div class="loader smooth" :style="{ opacity: loading ? 1 : 0 }">
      <img src="https://app.verified.eu/lib/iframes/common/assets/loader64.gif" />
    </div>    
    <div class="container main" v-if="!loading">
      <ve-row>
        <ve-col>
          <!-- #region form1 -->
          <div class="card border-0 shadow-sm">
            <div class="card-body">
              <ve-row>
                  <ve-col xs12 sm12>
                    <img :src="'./logo.png'" style="margin-right: 10px;max-width: 350px;"
                    />
                  </ve-col>
                  <br> 
                </ve-row>
              
              <br><br> 
             <ve-form  ref="form1" class="ml-3">
                <div>
                  <h5 style="padding-top: 20px;">{{__('sectionHead1')}}</h5>
                    <ve-row style="padding-top: 10px;">
                      <ve-col md6 xs12> 
                         <ve-form-group>
                            <label>{{ __("customer") }}</label>
                            <ve-input v-model="ud.customer" type="text" rules="required"></ve-input>
                          </ve-form-group>
                      </ve-col>
                      <ve-col md6 xs12> 
                         <ve-form-group>
                      		<label>{{ __("orgNo") }}</label>
                            <ve-input v-model="ud.orgNo" type="text" mask="######-####" rules="required|min:11"></ve-input>
                          </ve-form-group>
                      </ve-col>
                  </ve-row>
				          <ve-row style="padding-top: 10px;">
                      <ve-col md6 xs12> 
                         <ve-form-group>
                            <label>{{ __("guarantor") }}</label>
                            <ve-input v-model="ud.guarantor" type="text" rules="required"></ve-input>
                          </ve-form-group>
                      </ve-col>

                      <ve-col md6 xs12> 
                         <ve-form-group>
                      		<label>{{ __("orgNoOrSsn") }}</label>
                            <ve-input v-model="ud.orgNoOrSsn" type="number" rules="required"></ve-input>
                          </ve-form-group>
                      </ve-col>
                  </ve-row>
                  <h5 style="padding-top: 20px;">{{__('sectionHead2')}}</h5>
				          <ve-row style="padding-top: 10px;">
                      <ve-col md12 xs12> 
                         <ve-form-group>
                      		<label>{{ __("requestOrContractNum") }}</label>
                      <ve-input v-model="ud.requestOrContractNum" type="number" rules="required"></ve-input>
                    </ve-form-group>
                      </ve-col>
                  </ve-row>
                  <ve-row style="padding-top: 10px;">
                     <ve-col md12 xs12> 
                            <input style="display:none" ref="file" accept="image/*, application/pdf" type="file" @change="handleFileChange"/>
							              <button @click.prevent="$refs.file.click()" class="btn btn-primary">{{__('upload')}}</button>
                          <div style="padding-left: 30px;padding-top:20px;" v-if="ud.files">
                            <span class="atc-item" v-if="ud.files">{{ud.files.name}}&nbsp;&nbsp;&nbsp;&nbsp;<a class="remove-button-atc" @click="removeFile()">X</a></span>
                          </div>
						              <div v-if="!pdfUploaded" style="color:#b62537;font-size:12px;padding-top:10px" >{{__('pdfRequired')}}</div>
                      </ve-col>
                  </ve-row>
                  <h5 style="padding-top: 20px;">{{__('sectionHead3')}}</h5>
				          <div v-for="(recipient,index) in ud.recipients" :key="index">
                      <ve-row style="padding-top: 10px;">
                          <ve-col md6 xs12> 
                            <ve-form-group>
                              <label>{{ __("firstName") }}</label>
                              <ve-input v-model="recipient.firstName" type="text" rules="required"></ve-input>
                            </ve-form-group>
                          </ve-col>
                          <ve-col md6 xs12> 
                            <ve-form-group>
                                <label>{{ __("lastName") }}</label>
                                <ve-input v-model="recipient.lastName" type="text" rules="required"></ve-input>
                              </ve-form-group>
                          </ve-col>
                      </ve-row>

                      <ve-row style="padding-top: 10px;"> 
                          <ve-col md12 xs12> 
                            <ve-form-group>
                              <label>{{ __("email") }}</label>
                          <ve-input v-model="recipient.email" type="text" rules="required|email"></ve-input>
                        </ve-form-group>
                          </ve-col>
                      </ve-row>
                </div>
                </div>
				        <ve-row >
                 <ve-col md2 xs12 v-if="ud.recipients.length > 1">
                    <button class="btn btn-warning te" type="button" @click="ud.recipients.pop()">
                      {{ __("remove") }}
                    </button>
                  </ve-col>
                  <ve-col md2 xs12>
                    <button class="btn btn-success float-left" @click.prevent="addSigner" >{{__("add")}}</button>
                  </ve-col>
				        </ve-row>

                <ve-row style="padding-top: 20px;"> 
                    <ve-col md12 xs12> 
                      <ve-form-group>
                        <label>{{ __("confirmation") }}</label>
                        <ve-input v-model="ud.confirmationEmail" type="email" ></ve-input>
                      </ve-form-group>
                    </ve-col>
                </ve-row>
                
                <ve-row style="padding-top: 40px;"> 
                  <ve-col md12 xs12>
                    <button class="btn btn-primary float-left" @click.prevent="submitData">{{__("save")}}</button>
                  </ve-col>
                </ve-row>
             </ve-form>
             

            </div>
          </div>
          <!-- #endregion -->
        </ve-col>
      </ve-row>
    </div>
  </div>
</template>

<script>
export { default } from "./script.js";
</script>
<style>
.inline-input{
  display: inline;
}

.inline-input > input{
  display: inline;
  width: 100px;
}
#cover{
  width: 100%;
  overflow: scroll;
  height: 100%;
  position: absolute;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
</style>